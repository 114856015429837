<template>
  <div class="sonfig-container">
    <div class="sonfig_right">
      <h2 style="margin-bottom: 0; padding-top: 10px">账户总览</h2>
      <a-divider />
      <!-- 广告主 代理商-->
      <div v-if="this.roleIdPush === 14 || this.roleIdPush === 15">
        <a-row>
          <a-col :span="4">
            <div>
              <p class="Psize">{{ this.capitAl.totalIncomeAmount }}</p>
              <span class="Ssize">总充值金额(元)</span>
            </div>
          </a-col>
          <a-col :span="4">
            <span style="font-size: 24px">=</span>
          </a-col>
          <a-col :span="4">
            <div>
              <p class="Psize">{{ this.capitAl.arrivedAmount }}<span class="daoZhang">(到账金额)</span></p>
              <span class="Ssize">到账金额(元)</span>
            </div>
          </a-col>
          <a-col :span="4">
            <span style="font-size: 24px">+</span>
          </a-col>
          <a-col :span="4">
            <div>
              <p class="Psize">{{ this.capitAl.unArrivedAmount }}<span class="yinShou">(应收金额)</span></p>
              <span class="Ssize">未到账金额(元)</span>
            </div>
          </a-col>
        </a-row>
        <a-row style="margin-top: 30px">
          <a-col :span="4">
            <div>
              <p class="Psize">{{ this.capitAl.remainAmount }}</p>
              <span class="Ssize">账户剩余金额(元)</span>
            </div>
          </a-col>
          <a-col :span="4">
            <div v-if="this.roleIdPush === 14">
              <p class="Psize">{{ this.capitAl.expenseAmount }}</p>
              <span class="Ssize">总消耗金额(元)</span>
            </div>
            <div v-if="this.roleIdPush === 15">
              <p class="Psize">{{ this.capitAl.expenseAmount }}</p>
              <span class="Ssize">给广告主加款金额(元)</span>
            </div>
          </a-col>
        </a-row>
        <div class="adplace_left_search">
          <div style="margin-top: 30px">
            <a-spin :spinning="loading">
              <h1>充值记录</h1>
              <a-table
                class="m_table_cust"
                :pagination="false"
                :columns="columns"
                bordered
                :data-source="dataList"
                :rowKey="
                  (record, index) => {
                    return index
                  }
                "
              >
              </a-table>
              <!-- <a-pagination
              class="pagination"
              v-if="dataList.length > 0"
              show-size-changer
              :current="query.page"
              :total="total"
              @change="handleChangePage"
              @showSizeChange="onShowSizeChange"
              /> -->
            </a-spin>
          </div>
        </div>
      </div>
      <!-- 代理商 -->
      <div v-if="this.roleIdPush === 16">
        <a-row>
          <a-col :span="4">
            <div>
              <p class="Psize">{{ this.capitAl.totalIncomeAmount }}</p>
              <span class="Ssize">总充值金额(元)</span>
            </div>
          </a-col>
          <a-col :span="4">
            <span style="font-size: 24px">=</span>
          </a-col>
          <a-col :span="4">
            <div>
              <p class="Psize">{{ this.capitAl.payAmount }}</p>
              <span class="Ssize">充值金额(元)</span>
            </div>
          </a-col>
          <a-col :span="4">
            <span style="font-size: 24px">-</span>
          </a-col>
          <a-col :span="4">
            <div>
              <p class="Psize">{{ this.capitAl.refundAmount }}</p>
              <span class="Ssize">退款金额(元)</span>
            </div>
          </a-col>
        </a-row>
        <a-row style="margin-top: 30px">
          <a-col :span="4">
            <div>
              <p class="Psize">{{ this.capitAl.remainAmount }}</p>
              <span class="Ssize">账户剩余金额(元)</span>
            </div>
          </a-col>
          <a-col :span="4">
            <div>
              <p class="Psize">{{ this.capitAl.expenseAmount }}</p>
              <span class="Ssize">总消耗(元)</span>
            </div>
          </a-col>
        </a-row>
        <div class="adplace_left_search">
          <div style="margin-top: 30px">
            <a-spin :spinning="loading">
              <h1>充值/退款 记录</h1>
              <a-table
                class="m_table_cust"
                :pagination="false"
                :columns="columns1"
                bordered
                :data-source="transferLists"
                :rowKey="
                  (record, index) => {
                    return index
                  }
                "
              >
              </a-table>
              <!-- <a-pagination
              class="pagination"
              v-if="dataList.length > 0"
              show-size-changer
              :current="query.page"
              :total="total"
              @change="handleChangePage"
              @showSizeChange="onShowSizeChange"
              /> -->
            </a-spin>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { capitalDetail } from '@/api/financialManagementB'
// import mixDate from '@/mixins/initDate'
const columns = [
  {
    dataIndex: 'addTime',
    title: '充值时间',
    key: 'addTime',
    align: 'left'
  },
  {
    dataIndex: 'partyName',
    title: '公司名称',
    key: 'partyName',
    align: 'center'
  },
  {
    dataIndex: 'partyType',
    title: '账户类型',
    key: 'partyType',
    align: 'center',
    customRender: (text, record, index) => {
      if (record.partyType === '1') {
        text = '广告主'
      } else if (record.partyType === '2') {
        text = '代理商'
      } else if (record.partyType === '3') {
        text = '广告平台'
      }
      return text
    }
  },
  {
    dataIndex: 'incomeAmount',
    title: '充值金额',
    key: 'incomeAmount',
    align: 'center'
  },
  {
    dataIndex: 'accountNo',
    title: '账号',
    key: 'accountNo',
    align: 'center'
  },
  {
    dataIndex: 'username',
    title: '客户经理',
    key: 'username',
    align: 'center'
  },
  {
    dataIndex: 'incomeModel',
    title: '充值类型',
    key: 'incomeModel',
    align: 'center',
    customRender: (text, record, index) => {
      if (record.incomeModel === '1') {
        text = '预付费'
      } else if (record.incomeModel === '2') {
        text = '后付费'
      }
      return text
    }
  },
  {
    dataIndex: 'incomeSts',
    title: '收入状态',
    key: 'incomeSts',
    align: 'center',
    customRender: (text, record, index) => {
      if (record.incomeSts === '0') {
        text = '未到账'
      } else if (record.incomeSts === '1') {
        text = '已到账'
      }
      return text
    }
  },
  {
    dataIndex: 'sjAmount',
    title: '到账金额',
    key: 'sjAmount',
    align: 'center'
  },
  {
    dataIndex: 'sjTime',
    title: '到账时间',
    key: 'sjTime',
    align: 'center'
  },
  {
    dataIndex: 'approveSts',
    title: '充值状态',
    key: 'approveSts',
    align: 'center',
    customRender: (text, record, index) => {
      if (record.approveSts === '0') {
        text = '待审核'
      } else if (record.approveSts === '1') {
        text = '审核通过'
      } else if (record.approveSts === '-1') {
        text = '审核不通过'
      }
      return text
    }
  }
]
const columns1 = [
  {
    dataIndex: 'addTime',
    title: '充值时间',
    key: 'addTime',
    align: 'left'
  },
  {
    dataIndex: 'partyName',
    title: '公司名称',
    key: 'partyName',
    align: 'center'
  },

  {
    dataIndex: 'transferAmount',
    title: '金额',
    key: 'transferAmount',
    align: 'center'
  },
  {
    dataIndex: 'accountNo',
    title: '账号',
    key: 'accountNo',
    align: 'center'
  },
  {
    dataIndex: 'transferType',
    title: '操作类型',
    key: 'transferType',
    align: 'center',
    customRender: (text, record, index) => {
      if (record.transferType === '1') {
        text = '加款'
      } else if (record.transferType === '2') {
        text = '退款'
      }
      return text
    }
  }
]
export default {
  name: 'Money',
  data () {
    return {
      transferLists: [],
      bodyStyle: {
        width: 800
      },
      partyType: undefined,
      incomeModel: undefined,
      partyId: undefined,
      partyName: '',
      contractType: undefined,
      invoiceSts: undefined,
      contractStsPush: [
        {
          value: '未归档',
          table: 0
        },
        {
          value: '归档',
          table: 1
        }
      ],
      contractTypePush: [
        {
          value: '媒体和代理',
          table: 1
        },
        {
          value: '媒体和广告主',
          table: 2
        }
      ],
      contractNo: '',
      columns,
      columns1,
      supplier: 1,
      placeView: {},
      loading: false,
      addAbtestVisible: false,
      addGroupVisible: false,
      appList: [],
      placeList: [],
      industry: [],
      currentGroup: [],
      groupList: [],
      mode: 'inline',
      theme: 'light',
      fieldNames: { label: 'name', value: 'id', children: 'children' },
      BusNames: { label: 'name', value: 'id' },
      pid: [],
      query: {
        page: 1,
        limit: 10
      },
      total: 0,
      businessUser: [],
      pidBus: [],
      dateStrings: [],
      dateString: [],
      manager: undefined,
      dataList: [],
      times: [],
      advertiserIndustry: '',
      businessUserPush: '',
      keys: '',
      types: '',
      roleIdPush: '',
      capitAl: {}
    }
  },
  computed: {},
  mounted () {
    this.getCapitalDetail()
  },
  watch: {
    handler (val) {
      if (val.tradePid && val.tradeId) {
        this.pid = [val.tradePid, val.tradeId]
        this.query = JSON.parse(JSON.stringify(val))
      }
    }
  },
  methods: {
    handleClick (e) {
      this.keys = e.key
      this.getCapitalDetail()
    },
    changeMode (checked) {
      this.mode = checked ? 'vertical' : 'inline'
    },
    changeTheme (checked) {
      this.theme = checked ? 'dark' : 'light'
    },

    async getCapitalDetail () {
      this.loading = true
      this.dataList = []
      const res = await capitalDetail(this.query)
      if (res.code === 200) {
        this.loading = false
        this.dataList = res.data.incomeRecordList || []
        this.transferLists = res.data.transferList || []
        this.roleIdPush = res.data.roleId
        this.capitAl = res.data
      }
    },
    changeTradeId (val) {},
    changeBusiness (val) {},

    // 修改
    handleClickUpdatePlace (record) {},

    // 分页
    onShowSizeChange (current, size) {
      this.query.page = 1
      this.query.limit = size
      this.timer = setTimeout(() => {
        this.getCapitalDetail()
      }, 1000)
    },
    handleChangePage (page) {
      this.query.page = page
      this.timer = setTimeout(() => {
        this.getCapitalDetail()
      }, 1000)
    }
  }
}
</script>

<style lang="less" scoped>
.Psize {
  color: rgba(0, 0, 0, 0.85);
  font-size: 24px;
  margin-bottom: 0;
}
.Ssize {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}
.daoZhang {
  color: #22d90fd9;
  font-size: 12px;
}
.yinShou {
  color: #ff0000d9;
  font-size: 12px;
}
.sonfig-container {
  margin: 0 10px;
  padding: 10px 20px;
  background: #fff;
  .sonfig_left {
    border-radius: 5px;
    background: #fff;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    min-height: 620px;
  }
  .ant-menu-item-selected {
    background-color: fade(@primary-color,  5%);
    &::after {
      content: '';
      height: 100%;
      position: absolute;
      left: 0;
      border-radius: 5px;
      border-left: 3px solid @primary-color;
      border-right: none;
    }
  }
  .ant-menu-vertical .ant-menu-item::after,
  .ant-menu-vertical-left .ant-menu-item::after,
  .ant-menu-vertical-right .ant-menu-item::after,
  .ant-menu-inline .ant-menu-item::after {
    border-right: none;
  }
  .adplace_left_search {
    height: 50px;
    padding: 0 10px 10px 10px;
    padding: 8px;
    position: relative;
    height: 100%;
    background: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .sonfig_date {
    margin-left: 10%;
    margin-right: 10px;
  }
  .sonfig_industry {
    margin-left: 10px;
    margin-right: 10px;
  }
  .sonfig_businessUser {
    margin-right: 10px;
  }
  .ant-btn {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    touch-action: manipulation;
    height: 32px;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 10px;
  }
  .sonfig_right {
    flex-grow: 1;
    .ant-divider-horizontal {
      margin: 20px 0;
    }
  }
  .ant-table-wrapper {
    padding: 10px 0;
  }
}
</style>
